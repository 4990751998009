import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(
  theme => ({
    root: {
      // display: "flex",
      "&[hidden]": {
        display: "none",
      },
      // flex: 1,
      // width: "100%",
    },
  }),
  {
    classNamePrefix: "TabPanel",
  },
);

export const TabPanel = React.memo(
  /**
   * @typedef {object} TabPanelProps
   * @property {React.ElementType} [component="div"] The root component type.
   * @property {boolean} selected True if the tab is currently selected.
   * @property {boolean} [unmountOnHide] True to unmount after the tab has been
   * de-selected.
   *
   * @param {MergeElProps<"div", TabPanelProps>} props
   */
  function TabPanel(props) {
    const {
      // boxProps,
      children,
      className,
      component: TabPanelComponent = "div",
      selected,
      unmountOnHide,
      ...other
    } = props;
    const classes = useStyles();

    const [stayMounted, setStayMounted] = React.useState(selected);

    React.useEffect(() => {
      if (selected) setStayMounted(!unmountOnHide);
    }, [selected, unmountOnHide]);

    return (
      <TabPanelComponent
        className={clsx(classes.root, className)}
        role="tabpanel"
        hidden={!selected}
        {...other}
      >
        {selected || stayMounted ? children : undefined}
      </TabPanelComponent>
    );
  },
);
